import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

export const useGroupOptions = () => {
  const dispatch = useDispatch();
  const groups = useSelector(state => state.group.groups);
  const PlatformId = useSelector(state => state.frontend.platformData.id);

  if (!groups.length && PlatformId) {
    dispatch(getGroupsByPlatformAsync({ PlatformId }));
  }

  return groups.map(group => ({ id: group.id, label: group.name }));
};

export const readAllGroupsForDashboardAsync = createAsyncThunk(
  "group/readAll",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().group;
      const response = await getItemsWithPagination("/api/admin/groups", payload, metadata);
      return { groups: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const readMyGroupsAsync = createAsyncThunk("group/myGroups", async () => {
  try {
    const response = await axios.get(`/api/user/groups`);
    return { myGroups: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const upsertGroupAsync = createAsyncThunk(
  "group/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/group`, payload.group);
      return { group: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteGroupAsync = createAsyncThunk("/group/delete", async payload => {
  await axios.delete(`/api/group/${payload.group.id}`);
  return { id: payload.group.id };
});

export const getGroupsByPlatformAsync = createAsyncThunk(
  "/group/getGroupsByPlatform",
  async payload => {
    const response = await axios.get(`/api/groups/platform/${payload.PlatformId}`);
    return { groups: response.data };
  }
);

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    loading: true,
    error: null,
    // groups array is used both for frontend (groups list) and for admin dashboard
    groups: [],
    myGroups: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllGroupsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllGroupsForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "groups")
      )
      .addCase(readAllGroupsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(readMyGroupsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readMyGroupsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.myGroups = action?.payload?.myGroups;
      })
      .addCase(readMyGroupsAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getGroupsByPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getGroupsByPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.groups;
      })
      .addCase(getGroupsByPlatformAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(upsertGroupAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.group[1]) {
          // insert
          if (action.payload.group[0]) state.groups.unshift(action.payload.group[0]);
        } else {
          // update
          state.groups = (state.groups || []).map(group =>
            group.id === action.payload.group[0].id ? action.payload.group[0] : group
          );
        }
      })
      .addCase(upsertGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteGroupAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = state.groups.filter(group => group.id !== action.payload.id);
      })
      .addCase(deleteGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = groupSlice.actions;
export default groupSlice.reducer;
