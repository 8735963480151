import * as React from "react";

function SendIcon(props) {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M26.003 13.9039C25.7817 13.434 25.4127 13.0546 24.9558 12.827L7.93088 4.35525C6.80935 3.7965 5.45976 4.27852 4.91641 5.43184C4.63959 6.01946 4.61632 6.69985 4.85237 7.30601L7.81281 14.9154L4.85237 22.5255C4.39125 23.7193 4.95854 25.0715 6.11942 25.5456C6.38191 25.6529 6.66163 25.7086 6.94408 25.7099C7.28581 25.7099 7.62313 25.6306 7.93088 25.4778L24.9558 17.0038C26.0774 16.4451 26.5462 15.0573 26.003 13.9039ZM7.27576 24.0833C6.90186 24.2694 6.45202 24.1086 6.27102 23.7241C6.17878 23.5283 6.17111 23.3015 6.24982 23.0994L9.13204 15.6888H24.1457L7.27576 24.0833ZM9.13208 14.1419L6.24987 6.73131C6.12991 6.43591 6.20439 6.09478 6.43563 5.88051C6.65947 5.65622 6.99745 5.6036 7.27576 5.7498L24.1457 14.1419H9.13208Z"
          fill="#6875F5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="25.3734"
            height="26.0926"
            fill="white"
            transform="translate(0.630859 0.991211)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SendIcon;
