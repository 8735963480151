import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import slugify from "slugify";
import axios from "axios";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

// selectors

export const selectCollectionOptions = state => {
  const { currentUser } = state.auth;
  let collections = state.content.collections;
  // creators should only see their own collections
  if (currentUser.role === "creator")
    collections = collections.filter(col => col.UserId === currentUser.id);
  return collections.map(col => ({ id: col.id, label: col.name }));
};

// actions

export const readAllCollectionsForDashboardAsync = createAsyncThunk(
  "collection/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const {
        collection: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/collections", payload, metadata);
      return { collections: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertCollectionAsync = createAsyncThunk(
  "collection/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/collection`, payload.collection);
      return { collection: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const quickCollectionCreateAsync = createAsyncThunk(
  "collection/quickCreate",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { frontend, auth } = getState();
    const platformId = payload.PlatformId || frontend.platformData.id;
    const body = {
      name: payload.name,
      slug: slugify(payload.name),
      PlatformId: platformId,
      UserId: auth.currentUser.id
    };
    try {
      const response = await axios.post(`/api/collection`, body);
      return { collection: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteCollectionAsync = createAsyncThunk("/collection/delete", async payload => {
  await axios.delete(`/api/collection/${payload.collection.id}`);
  return { id: payload.collection.id };
});

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    loading: false,
    error: null,
    collections: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllCollectionsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllCollectionsForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "collections")
      )
      .addCase(readAllCollectionsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertCollectionAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickCollectionCreateAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickCollectionCreateAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(quickCollectionCreateAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(upsertCollectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.collection[1]) {
          // insert
          if (action.payload.collection[0]) state.collections.unshift(action.payload.collection[0]);
        } else {
          // update
          state.collections = (state.collections || []).map(collection =>
            collection.id === action.payload.collection[0].id
              ? action.payload.collection[0]
              : collection
          );
        }
      })
      .addCase(upsertCollectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteCollectionAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteCollectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collections = state.collections.filter(
          collection => collection.id !== action.payload.id
        );
      })
      .addCase(deleteCollectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = collectionSlice.actions;
export default collectionSlice.reducer;
