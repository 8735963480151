import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const readManyChatMessagesAsync = createAsyncThunk("chat/readMany", async payload => {
  try {
    const response = await axios.get(`/api/chat/${payload.contentId}`, {
      params: { sort: payload.sort }
    });
    return { chatMessages: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const postChatMessageAsync = createAsyncThunk("chat/upsert", async payload => {
  try {
    const response = await axios.post(`/api/chat`, payload);
    return { chatMessage: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const deleteChatMessageAsync = createAsyncThunk("/chat/delete", async payload => {
  try {
    // delete as true = delete, delete as false = undelete
    await axios.delete(`/api/chat/${payload.id}/${payload.delete}`);
    return { id: payload.id };
  } catch (error) {
    console.log(error);
  }
});

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    loading: true,
    error: null,
    chatMessages: [],
    chatEnabled: undefined,
    isNewMessage: false
  },
  reducers: {
    toggleChat: (state, action) => {
      if (action.payload) {
        state.chatEnabled = action.payload.chatEnabled;
      } else {
        state.chatEnabled = !state.chatEnabled;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readManyChatMessagesAsync.pending, state => {
        state.loading = true;
        state.isNewMessage = false;
      })
      .addCase(readManyChatMessagesAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (state.chatMessages?.length !== action.payload?.chatMessages?.length) {
          state.isNewMessage = true;
        }
        state.chatMessages = action.payload?.chatMessages.reverse();
      })
      .addCase(readManyChatMessagesAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(postChatMessageAsync.pending, state => {
        state.loading = true;
      })
      .addCase(postChatMessageAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postChatMessageAsync.rejected, state => {
        state.loading = false;
        console.log("postChatMessageAsync rejected");
      })
      .addCase(deleteChatMessageAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteChatMessageAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.chatMessages = state.chatMessages?.filter(cm => cm.id !== action.payload.id);
      })
      .addCase(deleteChatMessageAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { toggleChat } = chatSlice.actions;
export default chatSlice.reducer;
