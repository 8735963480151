import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

// selectors

export const selectFathomAnalyticsUrl = state => {
  const platform = state.platform;
  const fathomAnalyticsId = platform.platforms?.[0]?.fathomAnalytics;
  if (!fathomAnalyticsId) return null;

  const homepageUrl = platform.platforms?.[0]?.homepageUrl;
  // example: `https://app.usefathom.com/share/sqivptpz/inovo.io`
  const analyticsUrl = `https://app.usefathom.com/share/${fathomAnalyticsId}/${homepageUrl}`;

  return analyticsUrl;
};

// actions

export const readAllPlatformsForDashboardAsync = createAsyncThunk(
  "platform/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const {
        platform: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/platforms", payload, metadata);
      return { platforms: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertPlatformAsync = createAsyncThunk(
  "platform/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/platform`, payload.platform);
      return { platform: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deletePlatformAsync = createAsyncThunk("/platform/delete", async payload => {
  await axios.delete(`/api/platform/${payload.platform.id}`);
  return { id: payload.platform.id };
});

export const platformSlice = createSlice({
  name: "platform",
  initialState: {
    loading: true,
    error: null,
    platforms: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllPlatformsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllPlatformsForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "platforms")
      )
      .addCase(readAllPlatformsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.platform[1]) {
          // insert
          if (action.payload.platform[0]) state.platforms.unshift(action.payload.platform[0]);
        } else {
          // update
          state.platforms = (state.platforms || []).map(platform =>
            platform.id === action.payload.platform[0].id ? action.payload.platform[0] : platform
          );
        }
      })
      .addCase(upsertPlatformAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deletePlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deletePlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.platforms = state.platforms.filter(platform => platform.id !== action.payload.id);
      })
      .addCase(deletePlatformAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = platformSlice.actions;
export default platformSlice.reducer;
