import React, { useRef, useState } from "react";
import * as OutlineIcons from "../../assets/icons/outline";
import useClickOutside from "./hooks/useClickOutside";

export default function Multiselect({
  onChange,
  options = [],
  value,
  name,
  searchable = true,
  placeholder = "",
  searchPlaceholder = "Search",
  customActionText,
  customActionHandler,
  isCustomActionLoading
}) {
  const thisEl = useRef();
  // give chagne handler the same behavior as native input
  const boundOnChange = val => onChange({ target: { name, value: val } });
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = e => setSearchTerm(e.target.value);
  const toggleShow = () => setShow(!show);
  const filteredOptions = options.filter(o =>
    o.label.toUpperCase().includes(searchTerm.toUpperCase())
  );
  const isSearchTermExactMatch = options.some(
    o => o.label.toUpperCase() === searchTerm.toUpperCase()
  );
  const shouldShowCustomAction =
    searchable && customActionText && customActionHandler && searchTerm && !isSearchTermExactMatch;
  const isAnythingSelected = value && value.length > 0;

  const select = option => {
    let newValues;
    if (value.find(v => v === option.id)) {
      newValues = value.filter(v => v !== option.id);
    } else {
      newValues = [...value, option.id];
    }
    boundOnChange(newValues);
  };
  const remove = index => {
    boundOnChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };
  useClickOutside(thisEl, () => setShow(false));

  return (
    <div className="relative">
      <button
        type="button"
        onClick={toggleShow}
        className={`flex space-between bg-white w-full border border-gray-300 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        ref={thisEl}
      >
        <div className="flex flex-wrap flex-grow self-center h-full p-1">
          {!isAnythingSelected && (
            <span className="self-center text-gray-400 pl-2" aria-placeholder={placeholder}>
              {placeholder}
            </span>
          )}
          {(value || []).map((option, index) => (
            <div
              key={option}
              onClick={e => e.stopPropagation()}
              className="flex items-center p-1 m-1 rounded bg-gray-100 border"
            >
              <span className="text-xs font-normal leading-none max-w-full flex-initial px-1">
                {options.find(opt => opt.id === option)?.label}
              </span>
              <OutlineIcons.X
                className="inline h-4 w-4 text-red-700"
                onClick={() => remove(index)}
              />
            </div>
          ))}
        </div>
        <OutlineIcons.ChevronDown className="flex-none self-center h-10 w-10 p-3" />
      </button>
      {show && (
        <ul
          className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          role="listbox"
          ref={thisEl}
        >
          {searchable && (
            <li className="relative py-1 px-2">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <OutlineIcons.Search className="h-4 w-4 ml-2 text-gray-400" />
              </div>
              <input
                type="search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="border-gray-300 focus:shadow-outline-blue focus:border-blue-300 block w-full px-3 py-2 pl-9 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                placeholder={searchPlaceholder}
              />
            </li>
          )}
          {shouldShowCustomAction && (
            <li
              onClick={() => {
                if (!isCustomActionLoading) customActionHandler(searchTerm);
              }}
              className={`${
                isCustomActionLoading ? "text-gray-400" : "text-gray-900"
              } cursor-default select-none hover:bg-gray-100 relative py-2 pl-3 pr-9`}
            >
              <b>{customActionText}</b>
              <em> "{searchTerm}"</em>
            </li>
          )}
          {filteredOptions.map(option => (
            <li
              onClick={() => select(option)}
              className="text-gray-900 cursor-default select-none hover:bg-gray-100 relative py-2 pl-3 pr-9"
              key={option.id}
            >
              {(value || []).includes(option.id) && (
                <OutlineIcons.Check className="inline h-5 w-5 mr-2 text-green-600 " />
              )}
              <span className="font-normal truncate">{option.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
