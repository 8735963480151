import * as React from "react";

function VideoIcon(props) {
  return (
    <svg fill="none" viewBox="0 0 12 12" stroke="currentColor" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.1}
        d="M11.7288 3.05542C11.8953 3.14112 12 3.31271 12 3.5V8.5C12 8.6873 11.8953 8.85888 11.7288 8.94459C11.5623 9.03029 11.3618 9.01573 11.2094 8.90687L7.70938 6.40687C7.57798 6.31301 7.5 6.16148 7.5 6C7.5 5.83853 7.57798 5.68699 7.70938 5.59314L11.2094 3.09314C11.3618 2.98427 11.5623 2.96972 11.7288 3.05542ZM8.86023 6L11 7.52841V4.4716L8.86023 6Z"
        fill="currentColor"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.1}
        d="M1.5 3C1.22386 3 1 3.22386 1 3.5V8.5C1 8.77614 1.22386 9 1.5 9H7C7.27614 9 7.5 8.77614 7.5 8.5V3.5C7.5 3.22386 7.27614 3 7 3H1.5ZM0 3.5C0 2.67157 0.671573 2 1.5 2H7C7.82843 2 8.5 2.67157 8.5 3.5V8.5C8.5 9.32843 7.82843 10 7 10H1.5C0.671573 10 0 9.32843 0 8.5V3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default VideoIcon;
